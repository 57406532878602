import { configureStore } from "@reduxjs/toolkit";
import ConsultReducer from "./slice/consult.slice";
import ExamRecordReducer from "./slice/examRecord.slice";
import SnackbarReducer from "./slice/snackbar.slice";

export default configureStore({
  reducer: {
    consult: ConsultReducer,
    snackbar: SnackbarReducer,
    examRecord: ExamRecordReducer,
  },
});
