import { Box, Button, LinearProgress, styled } from "@mui/material";
import React, { Fragment, useContext, useEffect, useState } from "react";
import {
  EXAM_INSTRUCTION,
  OPERATION_VIEW_BODY,
  OPERATION_VIEW_DEFAULT,
  OPERATION_VIEW_VIDEO,
  ORGAN_ABDOMINAL,
  ORGAN_ARTERY,
  ORGAN_EAR,
  ORGAN_HEART,
  ORGAN_LUNG,
  ORGAN_NOSE,
  ORGAN_SKIN,
  ORGAN_TEMPERATURE,
  ORGAN_THEETH,
  ORGAN_THROAT,
} from "../../../assets/constant";
import { SynckitColors } from "../../../assets/SynckitColors";
import { ConsultContext } from "../ConsultIndex";
import BodyPartsIndex from "../body-card/BodyIndex";
import { PartsDataList } from "./PartsDataList";
import HeadNose from "../body-card/nose/HeadNose";
import HeadEar from "../body-card/ear/HeadEar";
import HeadThroat from "../body-card/throat/HeadThroat";
import Teeth from "../body-card/teeth/Teeth";
import Heart from "../body-card/heart/Heart";
import Artery from "../body-card/artery/Artery";
import Abdominal from "../body-card/abdominal/Abdominal";
import Lung from "../body-card/lung/Lung";
import HeadBT from "../body-card/head-bt/HeadBT";

import InstructionsIndex from "../intructions/InstructionsIndex";
import EquipmentView from "../StreamViews/EquipmentView";
import PatientViewDemo1 from "../StreamViews/PatientViewDemo1";

const Root = styled("div")(({ theme }) => ({
  background: "white",
  width: 926,
  height: 496,
  borderRadius: 8,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
  overflow: "hidden",
}));
const ProgressWrap = styled(Box)(({ theme }) => ({
  width: "100%",
  position: "absolute",
  top: 0,
}));

const BodyPartLabel = styled("span")(({ theme }) => ({
  position: "absolute",
  top: 24,
  left: "3%",
  color: SynckitColors.Gray["core"],
  fontWeight: 400,
  fontSize: 28,
  letterSpacing: 0.2,
}));
const PositionLable = styled("span")(({ theme }) => ({
  position: "absolute",
  top: 24,
  right: "3%",
  color: SynckitColors.Gray["core"],
  fontWeight: "bold",
  fontSize: 14,
  letterSpacing: 0.2,
  border: `1px solid ${SynckitColors.Gray[500]}`,
  borderRadius: 8,
  padding: " 8px 16px",
  width: 131,
  height: 36,
  textAlign: "center",
  background: "white",
}));

export default function OperationSectionIndex() {
  const [isLoading, setIsLoading] = useState(false);
  const {
    currentPartId,
    currentExamItem,
    setCurrentExamItem,
    operationView,
    setOperationView,
  } = useContext(ConsultContext);
  const [bodyMode, setBodyMode] = useState("front");
  const [lungMode, setLungMode] = useState("front");
  const [teethMode, setTeethMode] = useState("adult");
  useEffect(() => {
    setIsLoading(true);
    setTimeout(() => setIsLoading(false), 500);
  }, [operationView, currentExamItem, bodyMode, lungMode, teethMode]);

  return (
    <Root>
      {isLoading && (
        <ProgressWrap>
          <LinearProgress />
        </ProgressWrap>
      )}
      {operationView === OPERATION_VIEW_DEFAULT && (
        <Button
          variant="primary"
          onClick={() => {
            setIsLoading(true);
            setCurrentExamItem(ORGAN_SKIN);
            setOperationView(OPERATION_VIEW_BODY);
            setTimeout(() => setIsLoading(false), 1000);
          }}
        >
          Start Examination
        </Button>
      )}

      {operationView === OPERATION_VIEW_BODY && (
        <Fragment>
          <BodyPartLabel>
            {PartsDataList[currentPartId]["name"] || ""}
          </BodyPartLabel>
          <PositionLable>
            {bodyMode === "front" ? "R / L" : "L / R"}
          </PositionLable>

          {currentExamItem === ORGAN_SKIN && (
            <BodyPartsIndex bodyMode={bodyMode} setBodyMode={setBodyMode} />
          )}
          {currentExamItem === ORGAN_EAR && <HeadEar />}
          {currentExamItem === ORGAN_NOSE && <HeadNose />}
          {currentExamItem === ORGAN_THROAT && <HeadThroat />}
          {currentExamItem === ORGAN_THEETH && (
            <Teeth teethMode={teethMode} setTeethMode={setTeethMode} />
          )}
          {currentExamItem === ORGAN_HEART && <Heart />}
          {currentExamItem === ORGAN_ARTERY && <Artery />}
          {currentExamItem === ORGAN_ABDOMINAL && <Abdominal />}
          {currentExamItem === ORGAN_LUNG && (
            <Lung lungMode={lungMode} setLungMode={setLungMode} />
          )}
          {currentExamItem === ORGAN_TEMPERATURE && <HeadBT />}
        </Fragment>
      )}
	  <InstructionsIndex />
	  {
		<div id="remoteVideos" width="640" height="480"></div>
	  }
	  
      {/* {operationView === OPERATION_VIEW_VIDEO && <EquipmentView />} */}
      {/* {operationView === OPERATION_VIEW_VIDEO && <PatientViewDemo1 />} */}
    </Root>
  );
}
