import { Box, Button, Dialog, DialogContent, IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React, { useContext } from "react";
import MenuIcon from "@mui/icons-material/Menu";
import { SynckitColors } from "../../assets/SynckitColors";
import ExamItemMenu from "./ExamItemMenu";
import StreamViewsIndex from "./StreamViews/StreamViewsIndex";
import RecordGallery from "./RecordGallery";
import CommentSection from "./CommentSection";
import { IconDoctor } from "../../assets/SynckitIcons";
import TimerOutlinedIcon from "@mui/icons-material/TimerOutlined";
import SureToLeaveDialog from "./SureToLeaveDialog";
import { ConsultContext } from "./ConsultIndex";
import { ConsultThemeAry } from "./ConsultTheme";
import { OPERATION_VIEW_DEFAULT, ORGAN_DEFAULT } from "../../assets/constant";
import CheckIcon from "@mui/icons-material/Check";

const InfoCard = styled("div")({
  width: 154,
  height: 168,
  background: "rgba(255, 255, 255, 0.8)",
  borderRadius: 8,
  display: "flex",
  flexDirection: "column",
  justifyContent: "center",
  alignItems: "center",
  "& svg": {
    color: "#FFB54D",
    width: 63,
    height: 63,
    marginBottom: 10,
  },
  "& span": {
    color: "#162A4B",
    fontWeight: 600,
    fontSize: 14,
    lineHeight: "23px",
  },
});

export default function ConsultFullScreenDialog({ open, setOpen }) {
  const {
    setOperationView,
    currentExamItem,
    setCurrentExamItem,
    setCurrentPartId,
  } = useContext(ConsultContext);

  const HeaderBar = styled("div")(({ theme }) => ({
    minHeight: 72,
    background: ConsultThemeAry[currentExamItem]["headerBackground"],
    padding: "0px 24px",
    boxShadow: SynckitColors.Shadow[4],
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
  }));

  const StatusLabel = styled("div")({
    color: ConsultThemeAry[currentExamItem]["headerColor"],
    fontSize: 20,
    fontWeight: 600,
    display: "flex",
    alignItems: "center",
    "& div": {
      width: 12,
      height: 12,
      background: "linear-gradient(225deg, #67E9F1 0%, #24E795 100%)",
      borderRadius: "50%",
      marginRight: 8,
    },
  });
  const ControlStatusLabel = styled("div")({
    width: 174,
    height: 36,
    borderRadius: 100,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  });

  return (
    <Dialog fullScreen open={open} onClose={() => setOpen(false)}>
      <HeaderBar>
        <IconButton>
          <MenuIcon
            sx={{
              color: ConsultThemeAry[currentExamItem]["toggleMenuColor"],
            }}
          />
        </IconButton>
        {currentExamItem === ORGAN_DEFAULT ? (
          <StatusLabel>
            <div></div>On call
          </StatusLabel>
        ) : (
          <StatusLabel>Examination</StatusLabel>
        )}

        <Box sx={{ display: "flex" }}>
          <ControlStatusLabel
            sx={ConsultThemeAry[currentExamItem]["controlStatusLabelStyle"]}
          >
            <IconDoctor />
            &nbsp;{"Doctor control"}
          </ControlStatusLabel>
          {"　"}
          {currentExamItem === ORGAN_DEFAULT ? (
            <SureToLeaveDialog open={open} setOpen={setOpen} />
          ) : (
            <Button
              variant="primary"
              sx={ConsultThemeAry[currentExamItem]["saveBtnStyle"]}
              endIcon={<CheckIcon />}
              onClick={() => {
                setOperationView(OPERATION_VIEW_DEFAULT);
                setCurrentExamItem(ORGAN_DEFAULT);
                setCurrentPartId("");
              }}
            >
              Done
            </Button>
          )}
        </Box>
      </HeaderBar>
      <DialogContent
        sx={{
          background: ConsultThemeAry[currentExamItem]["mainBackground"],
        }}
      >
        <div
          style={{
            maxWidth: 1360,
            margin: "0px auto",
            overflow: "hidden",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              width: 1360,
            }}
          >
            <ExamItemMenu />
            <InfoCard>
              <IconDoctor
                style={{
                  color: ConsultThemeAry[currentExamItem]["primaryColor"],
                }}
              />
              <span>Co-consultation</span>
            </InfoCard>
            <InfoCard>
              <TimerOutlinedIcon
                style={{
                  color: ConsultThemeAry[currentExamItem]["primaryColor"],
                }}
              />
              <span>0:00:00</span>
            </InfoCard>
          </Box>
          <StreamViewsIndex />
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <RecordGallery />
            <CommentSection />
          </Box>
        </div>
      </DialogContent>
    </Dialog>
  );
}
