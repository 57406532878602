import { IconButton } from "@mui/material";
import { styled } from "@mui/system";
import React, { useContext, useEffect } from "react";
import DemoConsultationPatient from "../../../assets/demo/consultation_patient.png";
import VideocamIcon from "@mui/icons-material/Videocam";
import VolumeOffIcon from "@mui/icons-material/VolumeOff";
import MicOffIcon from "@mui/icons-material/MicOff";
import CropFreeIcon from "@mui/icons-material/CropFree";
import { ConsultContext } from "../ConsultIndex";
import {
  OPERATION_VIEW_DEFAULT,
  OPERATION_VIEW_VIDEO,
} from "../../../assets/constant";

const Root = styled("div")(({ theme }) => ({
  width: 410,
  height: 236,
  borderRadius: 8,
  overflow: "hidden",
  position: "relative",
  background: "black",
}));
const ButtonWrap = styled("div")(({ theme }) => ({
  position: "absolute",
  right: 12,
  bottom: 10,
  height: 216,
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
  alignItems: "center",
}));

const FunctionButton = styled(IconButton)({
  background: "rgba(255, 255, 255, 0.5)",
  width: 48,
  height: 48,
});

export default function PatientView() {
  const { operationView } = useContext(ConsultContext);

  const Root = styled("div")(({ theme }) => ({
    width: 410,
    height: 480,
    borderRadius: 8,
    overflow: "hidden",
    position: "relative",
    background: "black",
    pointerEvents: operationView === OPERATION_VIEW_DEFAULT ? "none" : "",
  }));

  const handleVideo = () => {
    navigator.mediaDevices
      .getUserMedia({ video: true, audio: true })
      .then((stream) => {
        const _config = {
          iceServers: [{ urls: "stun:stun.l.google.com:19302" }],
        };
        let _pc = new RTCPeerConnection(_config);

        let localVideoEl = document.getElementById("localVideo");
        localVideoEl.srcObject = stream;
        localVideoEl.autoplay = "true";
        localVideoEl.controls = "true";
        var urlPath = window.location.pathname;
		var param = window.location.search;
		var roomID = "bb56abdf-1fc1-4dbf-bb22-a90e30f9abe0";
		//var roomIDPro = "bb56abdf-1fc1-4dbf-bb22-a90e30f9abe0";
		//var roomIDTest = "11c13329-a2b7-41cc-adc8-6bb712d4a84d";
		if (urlPath == "/room")
		{
			roomID = param.replace("?", "");
		}
		console.log("roomID="+roomID);
		stream.getTracks().forEach((track) => _pc.addTrack(track, stream));
        var _url =
          "wss://synckit.viuto-aiot.com/v1/app/webrtc/room/" + roomID + "/ws";
          //"wss://test.viuto-aiot.com/room/bb56abdf-1fc1-4dbf-bb22-a90e30f9abe0/webSocket";
        let _ws = new WebSocket(_url);
		_pc.onicecandidate = (_e) => {
          if (!_e.candidate) return;
          else
            _ws.send(
              JSON.stringify({
                event: "candidate",
                data: JSON.stringify(_e.candidate),
              })
            );
		};
		var idx = 0;
		var label = "";
		var trackArr = [];
		var trackVideo = [];
		_pc.ontrack = function (event) {
			idx++;
			if (event.track.kind === 'audio') {
			  return
			}
			label = event.track.label;
			console.log("qidx="+idx);
			console.log("length="+event.streams.length);
			console.log("label="+label);
			console.log("trackCount="+event.streams[0].getVideoTracks().length);
      //

			if (trackArr.indexOf(label) == -1)
			{
				trackArr.push(label);
				trackVideo.push(event.streams[0]);
			}
			
			console.log("arrLenght="+trackArr.length);
			console.log("arr="+trackArr.toString());
			
			let el = document.createElement(event.track.kind)
			let elRtsp = document.createElement(event.track.kind)
			let elDef = document.createElement(event.track.kind)
      if( trackArr.length < 2 )
        return;
			
      var videoDoctor = document.getElementById('remoteVideoDoctor');
      //
			for(var i=0;i<trackArr.length;i++)
			{
				if (trackArr[i] != undefined)
				{
          console.log("arr["+i+"]="+trackArr[i]);
          console.log("default video");
          if (i == 0)
          {
            console.log("arr["+i+"]="+trackArr[i]+" -> remoteVideoDoctor");
            videoDoctor.srcObject = trackVideo[i]
            videoDoctor.autoplay = true
            videoDoctor.controls = true
            //
          }
          else
          {
            console.log("arr["+i+"]="+trackArr[i]+" -> elDef");
            elDef.srcObject = trackVideo[i]
            elDef.autoplay = true
            elDef.controls = true
            elDef.width="640"
            elDef.height="480"
            document.getElementById('remoteVideos').appendChild(elDef);
          }
				}
			}
      console.log("addEventListener.1");
      videoDoctor.addEventListener('loadedmetadata', function() {
        console.log('Remote video videoWidth.1: ' + this.videoWidth +
          'px,  videoHeight: ' + this.videoHeight + 'px');
          var w = this.videoHeight*960/544;
          if( this.videoWidth == w )
          {
            var tmp = elDef.srcObject;
            elDef.srcObject = videoDoctor.srcObject;
            videoDoctor.srcObject = tmp;
            console.log("switch!");
          }
      });
      //
      console.log("addEventListener.2");
      elDef.addEventListener('loadedmetadata', function() {
        console.log('Remote video videoWidth.2: ' + this.videoWidth +
          'px,  videoHeight: ' + this.videoHeight + 'px');
      });

			event.track.onmute = function(event) {
			  el.play();
			  elRtsp.play();
			  elDef.play();
			}

			event.streams[0].onremovetrack = ({track}) => {
			  if (el.parentNode) {
				el.parentNode.removeChild(el)
			  }
			  if (elRtsp.parentNode) {
				elRtsp.parentNode.removeChild(elRtsp)
			  }
			  if (elDef.parentNode) {
				elDef.parentNode.removeChild(elDef)
			  }
			}
		};
		_ws.onopen = (_e) => {
		  _ws.send(
			  JSON.stringify({
				event: 'token',
				data: {
					authorization: 'Bearer eyJhbGciOiJIUzUxMiIsInR5cCI6IkpXVCJ9.eyJleHAiOjE2NDE3MjAxOTYsInRva2VuX3R5cGUiOiJyZWZyZXNoIiwidXNlcl9pZCI6MjZ9.hxjn0rdrf9DMf49OibnmklSV6vOEySvjJdbgegO-nVwI6GJluju4q7R_m_Lkle1d41BHPevlfaRNBNDxmJeS8Q'
				}
			  })
			);
        };
		_ws.onclose = (_e) => {
          alert("WebSocket has closed!");
        };
        _ws.onerror = (_e) => {
          console.log("WebSocket Error:" + _e.data);
        };
        _ws.onmessage = (_e) => {
		  if (_e.data === "keep-alive") return;
		  let _msg = JSON.parse(_e.data);
          if (!_msg) console.log("Failed to parse WebSocket msg!");

          switch (_msg.event) {
            case "offer":
              let _offer = JSON.parse(_msg.data);
              if (!_offer) console.log("Failed to parse WebSocket answer");
              _pc.setRemoteDescription(_offer);
              _pc.createAnswer().then((_answer) => {
                _pc.setLocalDescription(_answer);
                _ws.send(
                  JSON.stringify({
                    event: "answer",
                    data: JSON.stringify(_answer),
                  })
                );
              });
              break;
            case "candidate":
              let _candidate = JSON.parse(_msg.data);
              if (!_candidate)
                console.log("Failed to parse WebSocket candidate");
              _pc.addIceCandidate(_candidate);
              break;
            default:
              break;
          }
        };
      })
      .catch((error) => console.log("error for  navigator.mediaDevices!"));
  };

  useEffect(() => {
    if (operationView === OPERATION_VIEW_VIDEO) handleVideo();
  }, [operationView]);
  return (
    <Root>
      {/* <figure> */}
      {/* <img
          src={DemoConsultationPatient}
          alt="Synckit"
          style={{ width: "100%", height: "100%", objectFit: "contain" }}
        /> */}
      {/* </figure> */}
      <video
        id="localVideo"
        style={{ objectFit: "cover" }}
        width="100%"
        height="240"
      ></video>
	  <video
        id="remoteVideoDoctor"
        style={{ objectFit: "cover" }}
        width="100%"
        height="240"
      ></video>
	  
	  {/* <ButtonWrap>
        <FunctionButton>
          <VideocamIcon />
        </FunctionButton>
        <FunctionButton>
          <VolumeOffIcon />
        </FunctionButton>
        <FunctionButton>
          <MicOffIcon />
        </FunctionButton>
        <FunctionButton>
          <CropFreeIcon />
        </FunctionButton>
      </ButtonWrap> */}
    </Root>
  );
}
