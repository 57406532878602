import { Divider, IconButton, Typography } from "@mui/material";
import { styled } from "@mui/system";
import React, { useContext, useRef } from "react";
import ArrowBackIosIcon from "@mui/icons-material/ArrowBackIos";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import Slider from "react-slick";
import DemoSkinRecord01 from "../../assets/demo/skin_record01.png";
import DemoSkinRecord02 from "../../assets/demo/skin_record02.png";
import DemoSkinRecord03 from "../../assets/demo/skin_record03.png";
import DemoSkinRecord04 from "../../assets/demo/skin_record04.png";
import DemoSkinRecord05 from "../../assets/demo/skin_record05.png";
import DemoSkinRecord06 from "../../assets/demo/skin_record06.png";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { ConsultContext } from "./ConsultIndex";
import { OPERATION_VIEW_DEFAULT } from "../../assets/constant";
import { SynckitColors } from "../../assets/SynckitColors";

const RecordHeader = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  padding: "16px 20px",
  "& span": {
    // fontFamily: "Inter",
    fontWeight: 700,
    fontSize: 18,
  },
  "& .MuiIconButton-root": {
    border: "1px solid #EBF2FA",
    borderRadius: 100,
    width: 28,
    height: 28,
  },
}));

const ImgCard = styled("div")({
  width: 102,
  height: 102,
  borderRadius: 8,
  // overflow: "hidden",
  position: "relative",
  "& span": {
    fontSize: 12,
    fontWeight: 700,
    position: "absolute",
    color: "white",
    bottom: 4,
    right: 8,
  },
});
const ButtonWrap = styled("div")(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  width: 68,
  "& .MuiIconButton-root": {
    border: "1px solid #EBF2FA",
    borderRadius: 100,
    width: 28,
    height: 28,
  },
}));
const RecordContent = styled("div")(({ theme }) => ({
  padding: "18px 24px",
}));
const settings = {
  dots: false,
  infinite: true,
  draggable: false,
  speed: 500,
  slidesToShow: 5,
  slidesToScroll: 1,
  arrows: false,
};
export default function RecordGallery() {
  const { operationView } = useContext(ConsultContext);
  const slickRef = useRef(null);

  const Root = styled("div")(({ theme }) => ({
    width: 668,
    height: 191,
    background: "rgba(255, 255, 255, 0.8)",
    borderRadius: 8,
    pointerEvents: operationView === OPERATION_VIEW_DEFAULT ? "none" : null,
  }));
  return (
    <Root>
      <RecordHeader>
        <span>Record</span>
        <ButtonWrap>
          <IconButton onClick={() => slickRef.current.slickPrev()}>
            <ArrowBackIosIcon fontSize="small" sx={{ marginLeft: 1 }} />
          </IconButton>
          <IconButton onClick={() => slickRef.current.slickNext()}>
            <ArrowForwardIosIcon fontSize="small" />
          </IconButton>
        </ButtonWrap>
      </RecordHeader>
      <Divider />
      <RecordContent>
        {operationView === OPERATION_VIEW_DEFAULT ? (
          <Typography
            variant="body1"
            sx={{ color: SynckitColors.Gray[600], mt: 3 }}
          >
            Record or capture to add records.
          </Typography>
        ) : (
          <Slider {...settings} ref={slickRef}>
            {demoImgList.map((item, index) => (
              <div key={index}>
                <ImgCard>
                  <figure>
                    <img
                      src={item.imgUrl}
                      alt={item.id}
                      style={{
                        width: 102,
                        height: 102,
                        objectFit: "cover",
                      }}
                    />
                  </figure>
                  <span>{item.duration}</span>
                </ImgCard>
              </div>
            ))}
          </Slider>
        )}
      </RecordContent>
    </Root>
  );
}

const demoImgList = [
  { id: 1, imgUrl: DemoSkinRecord01 },
  { id: 2, imgUrl: DemoSkinRecord02 },
  { id: 3, imgUrl: DemoSkinRecord03 },
  { id: 4, imgUrl: DemoSkinRecord04, duration: "0:14" },
  { id: 5, imgUrl: DemoSkinRecord05, duration: "0:05" },
  { id: 6, imgUrl: DemoSkinRecord06 },
];
