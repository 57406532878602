import { Grid } from "@mui/material";
import { styled } from "@mui/system";
import React from "react";
import OperationSectionIndex from "../operation-section/OperationSectionIndex";
import DoctorView from "./DoctorView";
import EquipmentView from "./EquipmentView";
import PatientView from "./PatientView";
import PatientViewDemo1 from "./PatientViewDemo1";
import PatientViewDemo2 from "./PatientViewDemo2";
import PatientViewDemo3 from "./PatientViewDemo3";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "row",
  justifyContent: "space-between",
  width: "100%",
  maxWidth: 1440,
  padding: "24px 0px",
}));
const VideoViews = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  justifyContent: "space-between",
}));
export default function StreamViewsIndex() {
  return (
    <Root>
      <OperationSectionIndex />
      {/* <EquipmentView /> */}
      {/* <PatientViewDemo1 /> */}

      {/* <Grid
        container
        spacing={2}
        direction="row"
        justify="center"
        alignItems="center"
        alignContent="center"
        wrap="nowrap"
      > */}
      {/* <Grid item xs={6}> */}
      {/* </Grid> */}
      {/* <Grid item xs={6}>
          <PatientViewDemo3 />
        </Grid> */}
      {/* </Grid> */}

      <VideoViews>
        {/* <PatientViewDemo2 /> */}
        {/* <DoctorView /> */}
        <PatientView />
      </VideoViews>
    </Root>
  );
}
